<!-- 教学场地使用情况 -->
<template>
    <div class="SiteUsage">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="日期查询">
                  <el-date-picker
                       v-model="form.queryDate"
                       type="week"
                       value-format="yyyy-MM-dd"
                       format="yyyy年 第 WW 周"
                        placeholder="选择周">
                     </el-date-picker>
            </el-form-item>
            <el-form-item>
                <div class="" style="margin-left: 22px;">
                    <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                        查询
                    </el-button>
                    <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                        重置
                    </el-button>
                </div>
            </el-form-item>
            
        </el-form>
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <!-- <button class="print">打印预览</button> -->
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.8%;flex: 1;" height="650" >
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="site_number" sortable="custom" label="场地码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="site_name" sortable="custom"  label="场地名称" align="center" width="200"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="area_count" sortable="custom" label="可排次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="week1morning" sortable="custom" label="周一上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week1afternoon" sortable="custom" label="周一下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week1night" sortable="custom" label="周一晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week2morning" sortable="custom" label="周二上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week2afternoon" sortable="custom" label="周二下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week2night" sortable="custom" label="周二晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week3morning" sortable="custom" label="周三上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week3afternoon" sortable="custom" label="周三下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week3night" sortable="custom" label="周三晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week4morning" sortable="custom" label="周四上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week4afternoon" sortable="custom" label="周四下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week4night" sortable="custom" label="周四晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week5morning" sortable="custom" label="周五上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week5afternoon" sortable="custom" label="周五下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week5night" sortable="custom" label="周五晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week6morning" sortable="custom" label="周六上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week6afternoon" sortable="custom" label="周六下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week6night" sortable="custom" label="周六晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week0morning" sortable="custom" label="周天上午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week0afternoon" sortable="custom" label="周天下午" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="week0night" sortable="custom" label="周天晚上" align="center" width="150"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="start_time" sortable="custom" label="开始日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="end_time" sortable="custom" label="截止日期" align="center" width="150">
            </el-table-column>
            <!-- <el-table-column  label="操作" align="center"  fixed="right" width="120" >
               <template slot-scope="scope">
                   <el-button class="census" size="mini" style="color:#FFFFFF ;"
                   @click="census(scope.$index, scope.row)" >
                       统计表
                   </el-button>
               </template>
            </el-table-column> -->
        </el-table> 
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import moment from "moment"
    export default{
        data() {
            return{
               form:{
                    queryDate:"",//日期查询  
               },
               tableData:[],//表格数据
               currentPage: 1,
               currentLimit:20,
               total: 0,
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url:'/api/classsign/area',
                    method:'POST',
                    data:{
                        dateQuery:this.form.queryDate,
                        page:this.currentPage,
                        limit:this.currentLimit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res)
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            query(){//查询
                console.log(this.form.queryDate)
                // this.currentPage=1
                // this.getList()
            },
            empty(){//重置
                this.form.queryDate=''//查询日期
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            census(index,row){//统计表
                this.$router.push({
                    path: "siteUsageCensus"
                })
            },
            fn2(a, b){
                return a.site_name.localeCompare(b.site_name)
            }
        }
        
    }
</script>

<style scoped="scoped">
    .SiteUsage{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        /* justify-content: space-between;
        flex-wrap: wrap; */
    }
    
    .form ::v-deep .el-input__inner {
        /* width: 276px; */
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 刷新 新增 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    /* 打印预览 */
    .print{
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin:0px 10px;
        cursor: pointer;
    }
    
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    .census{
        padding-left: 30px;
        background: url(../../assets/tj.png) 10px 4px no-repeat #29726C;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
